:root {
    --green-color: #005500;
    --grey-color: #666666;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Lato', sans-serif !important;
    font-size: 1rem;
    margin: 0;
    padding: 0;
    min-height: 100vh; /* Set minimum height to full viewport height */
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1; /* This makes the content section take up remaining space */
    /* Add other styles for your content */
    padding: 20px;
}

a {
    color: #000;
}

a:hover {
    text-decoration: none;
    color: #000;
}

button:focus {
    outline: none;
}

p {
    margin: 0;
}

/* Top Navbar */
.top-nav {
    background-color: #000;
    color: #fff;
}


/* Hero Section */
.hero {
    width: 100%;
    height: 65vh;
}

.carousel-item {
    height: 100%;
}

.hero-image {
    width: 100%;
    height: 90%;
    min-height: 65vh;
    max-height: 65vh;
}

/* Contacts */
.contacts {
    padding: 30px 0;
    border-top: 5px solid var(--green-color);
}

.contact-text {
    font-size: 14px;
    margin-bottom: 0.5rem;
    letter-spacing: 1px;
}

.contact-text-bold {
    font-weight: 500;
}

.contacts-middle p {
    margin-left: 50px;
}

.contacts-right p {
    margin-left: 100px;
}

/* Footer */
.footer {
    background: linear-gradient(0deg, #005500 20%, #FFFFFF 100%);
    color: #fff;
    padding: 25px 0 25px 40px;

}

.footer-text {
    font-size: 12px;
    margin-left: 50px;
    letter-spacing: 1px;
}

/* STORITVE CLASSES */
.main-section {
    padding: 50px 0;
}

.main-text {
    font-size: 1.25rem;
    letter-spacing: 1px;
}


/* Work Time */
.work-time {
    font-size: 1.25rem;
    font-weight: 500;
}

.work-main,
.work-main {
    margin-bottom: 20px;
}

.mobile-red {
    background-color: #005500;
    color: #fff;
    border-radius: 50px;
    padding: 10px 30px;
    display: inline-flex;
    align-items: center;
    margin-top: 20px;
    transition: all 0.5s ease-in-out;
}

.mobile-red:hover {
    transform: scale(1.2);
}

.mobile-icon {
    width: 20px;
    margin-right: 10px;
}

.mobile-red-text {
    font-weight: 400;
    letter-spacing: 5px;
    font-size: 16px;
}

.col-xl-4 {
    display: flex;
    flex-direction: column;
}


a.telefonska {
    color: white;
}

img {
    object-fit: cover;
}
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Center the title on larger screens */
.text-center-lg {
    text-align: center;
}

/* Center the title on smaller screens */
@media (max-width: 991px) {
    .text-center-lg {
        text-align: left;
    }
}

/* Hide the logo on smaller screens */
@media (max-width: 767px) {
    .navbar .navbar-brand {
        display: none;
    }

    .navbar .navbar-collapse {
        justify-content: center;
    }
  }


.mobile {

    padding: 5px 30px;
    font-size: 0.8rem;
    font-weight: 600;
    margin-right: 5rem;
    display: flex;
    align-items: center; /* Center vertically */
    letter-spacing: 2px;
    display: flex !important;
}



.mobile-number {
    display: flex;
    align-items: center; /* Center vertically */
}
.logo {
    margin-right: 50px; /* Adjusted margin value */
}
.mobile .mobile-icon {
    margin-right: 15px;
}

@media (max-width: 932px) {
    .mobile {
        display: none !important; /* Hide the mobile element on screens with a max width of 767px (typical mobile screens) */
    }
    .contact .contacts{
        text-align: center;
    }
}


